<template>
<div class="container-fluid">
         <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>



            <div class="col-md-12">
                <div class="col-lg-12 col-md-12 ">
                <div class="row">
                    <div class="col-md-4">
                    <form @submit.prevent="onSearch()" class="input-group no-border">
                        <input type="text" v-model="filter.phone" class="form-control" placeholder="بحث برقم الهاتف">
                        <button @click="onSearch()" type="submit" class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button>
                    </form>
                    </div>
                    <div class="col-md-4">
                          <Select class_bs="col-md-12" :delete_class="true"  id="status_id" title="حالة الطلبية" :Items="CustomStatus" Mykey="status_id" :errors="service_error" :MyValue="filter.status_id" @Select="HandleSelect('status_id',$event)"/>

                    </div>
                    <div class="col-md-4">
                          <PerPage class_bs="col-md-6" id="limit" title="كل صفحة"  Mykey="limit"  :MyValue="filter.limit" @Select="HandleSelect('limit',$event)"/>

                    </div>
                
                </div>
                </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة الطلبات</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          اسم الزبون
                        </th>
                        <th>
                          رقم الهاتف
                        </th>
                        <th>
                          عدد المنتجات
                        </th>
                        <th>
                          اجمالي السعر
                        </th>
                        <th>
                          نوع التوصيل
                        </th>
                        <th>
                          حالة الطلب
                        </th>
                        <th>
                          تاريخ الطلب
                        </th>
                        
                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(sale,index) in SalesList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{sale.client.first_name}}
                          </td>
                          <td>
                            {{sale.client.phone}}
                          </td>
                          <td>
                            {{sale.ProductsSale.length}}
                          </td>
                          <td>
                            {{sale.total_price}}
                          </td>
                          <td>
                            {{sale.delivery_method == 1?'شركة توصيل':'شخصيا'}}
                          </td>
                          <td>
                            {{sale.status.name.ar}}
                          </td>
                          <td>
                            {{sale.created_at}}
                          </td>

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">
                            <router-link   :to="{name: 'admin.dashboard.sales.view', params: {uuid: sale.uuid}}" class="btn btn-success btn-sm" > 
                             عرض</router-link>
                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.sales.update.status', params: {uuid: sale.uuid}}" class="btn btn-warning btn-sm" > 
                             تغيير حالة الطلبية</router-link>
                             <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(sale)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="SalesList.meta.last_page > 1" :pagination="SalesList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../store/index'
import {mapState} from 'vuex';

import Pagination from '../../../components/Pagination.vue'
import PerPage from '../../../components/PerPage'
import ConfirmAlert from '../../../components/ConfirmAlert'
import SuccessAlert from '../../../components/SuccessAlert'
import Select from '../../../components/Form/TextSelect'

export default {
  data(){
   return{
   filter:{
        phone:'',
        page:1,
        paginate:1,
        limit:'100',
        status_id:'1',
    },  
     AlertTitle:'',
     AlertMessage:'',
     SelectedSale:null,
     sureResult:true,
   }
  },
  computed:{
    ...mapState({
        SalesList:state=>state.admin.sale.SalesList,
        StatusList:state=>state.admin.status.StatusList,

    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
    CustomStatus(){
      let Status=[];
      this.StatusList.data.forEach(element => {
          Status.push({
              id          : element.id,
              name        : element.name.ar,
              action      : element.action,
              action_type : element.action_type,
          })
      });
      return Status;
    },
    
   },
   methods:{
     HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
       this.filter.page=page;
         store.dispatch('admin/fetchSalesList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
        onSearch(){
          store.commit('admin/PleaseStartLoading');
                this.filter.page=1;
                store.dispatch('admin/fetchSalesList',this.filter).then(()=>{
                  store.commit('admin/PleaseStopLoading');
                }).catch((error)=>{
                  store.commit('admin/PleaseStopLoading');
                });  
        },  
        YesIamSure(value){
        if(value && this.sureResult){
            this.sureResult=false;
            this.onDelete();
        }
        },
        CancelAlert(){
        this.sureResult=false;
        },
        ShowConfirm(sale){
                this.AlertTitle="معلومات",
                this.AlertMessage="هل انت متاكد من الحذف ؟";
                this.$refs.MyConfirmAlert.showModel();
                this.SelectedSale=sale;
        },
         onDelete(){
           store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteSale',this.SelectedSale.uuid).then((Response)=>{
            store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchSalesList',this.filter).then(()=>{
             this.sureResult=true;
            }).catch(error=>{

            })
          }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
      components:{
       Pagination,
       PerPage,
       ConfirmAlert,
       SuccessAlert,
       Select
   },

  beforeRouteEnter(to,from,next){     
      store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchStatusList', {limit:99}).then((responseStatus) => {
      store.dispatch('admin/fetchSalesList',{page:1,phone:'',paginate:1,limit:100,status_id:'1'}).then(()=>{
         store.commit('admin/PleaseStopLoading');
          next();  
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });       
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });      

    },
}
</script>

<style>

</style>